<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            dark
            color="secondary"
            class="ma-2"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <div class="d-flex justify-space-between align-center mb-5">
          <v-card-title class="pl-6">{{ description }}' Students</v-card-title>

          <v-card-title
            class="d-flex justify-end flex-wrap align-center pa-0 ma-2"
          >
            <div>
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                outlined
                dense
                hide-details
              ></v-text-field>
            </div>
            <v-btn
              class="mx-2"
              dark
              color="primary"
              @click="
                $router.push({
                  path: `/app/customer/${id}/students/create`,
                })
              "
            >
              <v-icon left>mdi-plus</v-icon>

              Add Student
            </v-btn>
          </v-card-title>
        </div>

        <v-data-table
          :loading="getCustomersLoading"
          :headers="headers"
          :options.sync="options"
          :items="getCustomerStudents"
          :search="search"
          item-key="id"
          class="elevation-1 data-table"
        >
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-center justify-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="showRemoveDialog(item)"
                    class="mx-1"
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                    icon
                  >
                    <v-icon>
                      {{
                        item.disabled
                          ? "mdi-account-check"
                          : "mdi-account-cancel"
                      }}</v-icon
                    >
                  </v-btn>
                </template>
                <span>{{ item.disabled ? "Enable" : "Disable" }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-card>
      <template v-if="selected">
        <v-row justify="center">
          <v-dialog max-width="350" v-model="dialog">
            <v-card>
              <v-card-title>Disable Student</v-card-title>
              <v-card-text>
                Are you sure you want to disable this student?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false"> Cancel </v-btn>
                <v-btn color="danger" text @click="remove"> Disable </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { get } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Customer Students",
  },

  data() {
    return {
      id: get(this.$route, "params.id"),
      loading: false,
      dialog: false,
      selected: null,
      search: null,
      options: {},
      headers: [
        {
          text: "Name",
          value: "name",
          sortable: false,
        },
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    };
  },

  mounted() {},

  methods: {
    ...mapActions(["toggleDisabledStatus"]),

    showRemoveDialog(student) {
      this.selected = student;
      this.dialog = true;
    },

    async remove() {
      const payload = {
        disabled: get(this.selected, "disabled"),
      };

      await this.toggleDisabledStatus({
        id: get(this.selected, "id"),
        payload,
      });

      this.selected = null;
      this.dialog = false;

      this.$store.dispatch("showSnackbar", "Student removed.");
    },
  },
  computed: {
    ...mapGetters([
      "getCustomer",
      "getCustomersLoading",
      "getCustomerStudents",
    ]),

    description() {
      return get(this.getCustomer, "description");
    },
  },
};
</script>
<style lang="scss" scoped></style>
